<template>
    <div class="profile-pic-inner">
        <h2>// SPENCER</h2>
        <img src="../assets/transBG_profile_pic.png" alt="Image of Spencer Haugh">
      </div>
</template>

<script>
export default {
  name: 'ProfileImage',
};
</script>

<style scoped>
  img {
    width: 65%;
    margin-top: -4%;
    border-radius: 50%;
    animation: slide-in-blurred-left 1.25s cubic-bezier(0.165, 0.84, 0.44, 1) .25s both;
  }
  .profile-pic-inner {
      max-width: 350px;
  }
  h2 {
    color: #f5af19;
  }
  @keyframes floating {
    0% {transform: translate(0, 0px);}
    50% {transform: translate(0, 15px);}
    100% {transform: translate(0, -0px);}
  }

  /* ----------------------------------------------
 * Generated by Animista on 2021-9-6 11:42:58
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@-webkit-keyframes slide-in-blurred-left {
        0% {
        -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
        transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
        -webkit-transform-origin: 100% 50%;
        transform-origin: 100% 50%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
        }
        100% {
        -webkit-transform: translateX(0) scaleY(1) scaleX(1);
        transform: translateX(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
        }
}
@keyframes slide-in-blurred-left {
        0% {
        -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
        transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
        -webkit-transform-origin: 100% 50%;
        transform-origin: 100% 50%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
        }
        100% {
        -webkit-transform: translateX(0) scaleY(1) scaleX(1);
        transform: translateX(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
        }
}
</style>
