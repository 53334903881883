<template>
    <div class="about-skills-inner">
        <h2>// SKILLS</h2>
        <div class="skills-container">
            <div class="skills-list">
                <ul>
                    <li><i class="fab fa-js"></i> Javascript</li>
                    <li><i class="fab fa-css3-alt"></i> CSS</li>
                    <li><i class="fab fa-html5"></i> HTML</li>
                    <li><i class="fab fa-git-alt"></i> Git/Github/Gitlab</li>
                    <li><i class="fab fa-node-js"></i> Node.js</li>
                </ul>
            </div>
            <div class="skills-list">
                <ul>
                    <li><i class="fab fa-angular"></i> Angular</li>
                    <li><i class="fab fa-node"></i> Express</li>
                    <li><i class="fab fa-react"></i> React</li>
                    <li><i class="fab fa-vuejs"></i> Vue.js</li>
                    <li><i class="fas fa-universal-access"></i> A11y</li>
                </ul>
            </div>
        </div>
    </div>

</template>

<script>
export default {
  name: 'Skills',
};
</script>

<style scoped>
    ul {
        list-style: none;
    }
    li {
        transition: all .5s ease-in-out;
    }
    .skills-container {
        background-color: rgba(238, 238, 238, 0.3);
        border: 1px solid #555;
        display: flex;
        justify-content: center;
        border-radius: 10px;
    }
    .skills-list {
        padding-left: 5px;
        padding-right: 40px;
        text-align: left;
        font-size: 1.2rem;
    }
    h2 {
    color: #f5af19;
    }
    li:hover {
        color: #f5af19;
        transform: translateX(-10px)
    }
    @media (max-width: 600px) {
      .skills-list {
          font-size: 0.7rem;
          padding-top: 10px;
      }
  }
  /* ----------------------------------------------
 * Generated by Animista on 2021-9-6 11:42:58
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-blurred-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-blurred-left {
  0% {
    -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
            transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
            transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-left {
  0% {
    -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
            transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
            transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
</style>
